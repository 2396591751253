import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Icon from 'components/icon'
import logo from '../../content/images/logo.svg'

class Service extends React.Component {
  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="私たちのサービスについて" />
        <header className="jumbotron rounded-0">
          <div className="container mw7">
            <h1 className="d-flex align-items-top">私たちのサービスについて</h1>
            <p className="mb-0 text-muted font-weight-bold">
              優良な遺品整理企業を業者とやり取りする前に、事前に見積もりで比べることができます。
            </p>
          </div>
        </header>

        <section>
          <div className="container text-justify mw7 py-3">
            <h2>私たちの目的</h2>
            <p className="text-muted font-weight-bold mb-4">
              お客様の視点を第一に考え、他サービスが抱える構造的な問題を一挙に解決するために。
            </p>
            <p>
              私たちeイーセイリは、あなたの代わりに複数社から見積もりをお取りして、公正な価格競争に基づく最安値の業者を短時間で、手間なくご紹介するサービスです。
            </p>
            <p>
              私たちは、他のサービスにあるような、結局やりとりが多くなって面倒な一括見積もりサービスとは違います。
            </p>
            <p>
              一般的な「一括見積もりサービス」では、お客様が入力された情報をもとに、お見積もり金額を出して、最安値を決めています。
            </p>
            <p>
              しかしながら、遺品整理の価格は、間取りや物量や搬出経路など複数の要素で決まります。例えば、エレベータのない団地での階段の搬出となれば、エレベーター設置の場合と比較して高くなるケースが多いのです。
            </p>
            <p>
              このため、いくら情報を画像や文章にてお送りいただいても、
              <strong>
                他一括サービスでは、結局は業者による目視の見積もりが必要
              </strong>
              であることが多くなります。
            </p>
            <p>
              これは、お客様の情報を見て名乗りをあげた業者が、直接お客様の作業物件まで訪問し、目視で見積もり依頼を行うことを意味します。つまり、結果として全ての会社を該当の物件に呼んで「詳細な見積もり」を取ることになるのです。
            </p>
            <p>
              すなわち、5社の紹介を受けた場合、5社分の時間をとって見積価格を取った上で、比べるよりほか無くなるのです。これだけで1日以上の時間を無駄にします。
            </p>
            <p>
              このような事態になれば、せっかくの一括見積もりサービスである意味がなくなってしまい、本末転倒だと言わざるを得ません。
            </p>
            <p>
              もちろん、こうしたことにならないように事前に参考価格程度の一括料金比較を行うサービスもございますが、料金やサービス面で明確な差を見極められないことも多いのが実情です。
            </p>
            <p>
              このように、引越し一括見積もりや、車の査定価格一括見積もりサイトのシステムを、そのまま遺品整理に持ち込んだようなサービスが抱える構造的な問題を解決し「よりお客様が負担なく品質を犠牲にすることのない最安値の価格を実感していただけるサービスを作ろう」と立ち上がったのがeイーセイリなのです。
            </p>
            <h2>eイーセイリは優良遺品整理業者をご紹介します。</h2>
            <p className="text-muted font-weight-bold mb-4">
              適正な基準を設け、お客様に不当な請求を行う業者が出ない制度
            </p>
            <p>
              eイーセイリの登録事業者になるためには、厳格な審査と基準が設けられています。
            </p>
            <p>
              公正な価格競争や、競合他社に先んじて不当に案件数を確保するなどの行為が横行しないようにするためです。
            </p>
            <p>
              その一部をご紹介すると、遺品整理サービスを創業してから5年以上経過している企業であることや、これまでの実績や作業の方法に対して、適正なサービスを提供できているのかを検証すること、などが挙げられます。
            </p>
            <p>
              万が一、クレームや不正行為が認められる場合には、見積もりに参加することができません。
            </p>
            <h2>ご紹介だけではなく、一括のお見積もりをお出しします。</h2>
            <p className="text-muted font-weight-bold mb-4">
              今お持ちの見積もりに疑問を感じている方にこそ利用してほしい一括見積もり
            </p>
            <p>
              では、私たちは、これまで述べてきたお客様への不安をどのように解決したのかをお伝えします。
            </p>
            <p>
              まず、私たちは、遺品整理における価格決定の要素を約120段階にまで細分化しました。
            </p>
            <p>
              この細分化した指標を用いて、お客様がお申し込みいただいた内容から、見積もりの参考価格を極めて正確に割り出すことが可能になったのです。
            </p>
            <p>
              それだけではありません。お申し込みフォームの内容だけで、ご不安なお客様のために、eイーセイリは、この細分化した価格設定要素を目視で確認できるスタッフも配置しました。
            </p>
            <p>
              このスタッフが、専任でお客様の元に伺い、課題解決に向けての情報やご相談に対応いたします。
              お客様は、たった1度だけ訪問査定員に課題やご相談をおっしゃっていただくだけで、参加企業全社に必要十分な情報を提供することができ、最大で5社のお見積もりをお受け取りいただけます。
            </p>
            <p>これこそがeイーセイリの強みです。</p>
            <h3>お客様はお選びいただいた業者だけにご連絡いただきます。</h3>
            <p>
              以上の方法で、一括お見積もりをご依頼いただいたら、お客様は見積もりが到着するまでお待ちいただくだけです。
            </p>
            <p>
              仮に、相続税やリフォーム・解体などについてなど、他の課題などがございましたら、こうした課題にも解決に向けてご案内いたしますので、お申し付けください。
            </p>
            <h3>1社お選びいただき、そのほかの業者へのお断りを代行します。</h3>
            <p>
              eイーセイリでは、最大で5社のお見積もり結果をお客様にお伝えします。
            </p>
            <p>
              その中から、お客様のご希望に沿った遺品整理業社を1つお選びください。
            </p>
            <p>
              そのほかの業者についてのお断りなどについては、全て当社で代行いたしますので、ご安心ください。
            </p>
            <p>
              安さだけではなく、優良事業者によるサービス品質を保証し、お客様は1度ご依頼いただくだけで、手軽に最安値を把握していただけるサービスがeイーセイリです。
            </p>
            <p>
              <strong>
                さらにお客様に対してのご紹介料などの請求は一切ございません。完全無料でご利用いただけます。
              </strong>
            </p>
            <p>
              お手元のお見積もりに不安を感じていたり、多くの業者を家に入れることに抵抗を感じていらっしゃる方、最安値を気軽に知りたいというお客様はぜひ一度一括見積もりで、安さと手軽さを感じてください。
            </p>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Service
